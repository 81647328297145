import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import Header from "../components/header"

import Section from "../components/section"

const ComplaintsPolicyPage = props => (
  <Layout>
    <SEO title="Privacy Policy" />

    <BackgroundImage
      fluid={props.data.privacyPolicyImage.childImageSharp.fluid}
      img="Tall buildings viewed from ground level"
    >
      <Header headerText="Complaints Policy"></Header>
    </BackgroundImage>
    <Section>
      <h2>
        <strong>How do I</strong> complain?
      </h2>

      <p>
        In the event that you ever have to complain, you should contact: The
        compliance officer at Stonyroyd House, 8 Cumberland Road, Leeds, West
        Yorkshire, LS6 2EF or by phone 0113 224 2800.
      </p>

      <h2>
        <strong>Do I have to</strong> make the complaint in writing?
      </h2>

      <p>
        No. Although it is preferred, you may telephone us on the above number
        to make a complaint. You should make it clear that you are registering a
        complaint and make a note of the name of the person you spoke to and the
        date on which you rang. You will be given a complaints reference number
        which is our acknowledgement that you have made a formal complaint.
      </p>

      <h2>
        What happens <strong>next</strong>?
      </h2>

      <ol type="a">
        <li>
          {" "}
          We will acknowledge your complaint promptly in accordance with the
          rules of our regulatory body and set out our understanding of your
          complaint.
        </li>
        <li>
          If you do not hear from us, please check first to see that we have
          actually received your complaint.
        </li>
        <li>
          We will investigate your complaint and help you with any questions
          that you may have.
        </li>
        <li>
          If your complaint is not resolved, we will keep you informed with
          regard to its progress.
        </li>
        <li>
          After we have investigated your complaint, we will write to you
          explaining the outcome of the investigation.
        </li>
      </ol>

      <h2>
        <strong>How long</strong> will the investigation take?
      </h2>

      <p>
        We will try to handle your complaint as quickly and fairly as possible.
        We will endeavour to keep you up to date with the progress of our
        investigation and to complete our investigation within 8 weeks of
        receiving the complaint. If our investigations are not complete 8 weeks
        from the date we received your complaint, we must send you a further
        letter which must explain clearly that we are continuing the
        investigation into your complaint and explain why the matter remains
        unresolved.
      </p>

      <h2>
        <strong>What happens</strong> at the end of the investigation?
      </h2>

      <p>
        Within 7 business days of completion of our investigation we will send
        you a letter explaining the outcome of the investigation and giving
        details of any offer of settlement, which we are prepared to make.
      </p>

      <h2>
        <strong>What if</strong> I am not satisfied with your decision?
      </h2>

      <p>
        If, for any reason, you are not happy with the decision reached, you
        have the right to refer your complaint to the Financial Ombudsman
        Service within 6 months of the date we write to you with its decision.
        Full details of the Financial Ombudsman Service can be found at{" "}
        <a href="https://www.financial-ombudsman.org.uk">
          www.financial-ombudsman.org.uk
        </a>{" "}
        or by contacting them on 0800 023 4567.
      </p>
    </Section>
  </Layout>
)

export default ComplaintsPolicyPage

export const pageQuery = graphql`
  query {
    privacyPolicyImage: file(relativePath: { eq: "privacy-policy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
